import { MODAL_TYPE } from '@src/lib/constant'
import { RootState } from '@src/reducers/rootReducer'
import { useSelector } from 'react-redux'
import ContactUs from './contactUs'
const PopUp = (): JSX.Element => {
  const { popUp } = useSelector((state: RootState) => state.app)

  return <> {popUp.isShow && popUp.type === MODAL_TYPE.contactUs && <ContactUs />} </>
}

export default PopUp
