import * as actions from '@src/actions/authActions'
import * as errorActions from '@src/actions/errorActions'
import config from '@src/config'
import { API_ENDPOINT } from '@src/lib/constant'
import { i18n } from '@src/lib/i18n'
import getOauthClient from '@src/lib/oAuth'
import * as types from '@src/reducers/authReducer'
import { OAuthValue } from '@src/types/store/auth'
import { push } from 'connected-next-router'
import { camelizeKeys } from 'humps'
import querystring from 'querystring'
import { combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { AjaxError, AjaxResponse, ajax } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

function mapUrlHashToState(hash: string) {
  const queries = querystring.parse(hash.substring(1))
  const camelizedQuery = camelizeKeys(queries) as OAuthValue
  return {
    ...camelizedQuery,
    expiresAt: Date.now() + Number(camelizedQuery.expiresIn) * 1000
  }
}

export const loginUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.LOGIN_USER),
    map(() => {
      const urlHash = state$.value.router.location.hash
      const OAuthValue = mapUrlHashToState(urlHash)
      return actions.loginStoreToken(OAuthValue)
    })
  )

export const loginRewritePathEpic = (action$) =>
  action$.pipe(
    ofType(types.LOGIN_STORE_TOKEN),
    map(() => {
      return actions.loginRedirected()
    })
  )

export const getTempTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_TEMP_TOKEN),
    switchMap(() => {
      const { WELAB_PAY_HOST } = config
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.docUploadQrToken}`, // /v1/users/doc_upload_qr_token
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        map((data: AjaxResponse) => {
          const { token, expireAt } = camelizeKeys(data.xhr.response) as any
          return actions.getTempTokenSuccess(token, expireAt)
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(
              errorActions.changeError({
                display: false,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            ),
            of(actions.getTempTokenFailure())
          )
        })
      )
    })
  )

export default combineEpics(loginUserEpic, loginRewritePathEpic, getTempTokenEpic)
