import { clearAuth } from '@src/actions/authActions'
import { changeError, changeErrorComplete } from '@src/actions/errorActions'
import config from '@src/config'
import { submitEpicErrorToBugsnag } from '@src/lib/bugsnag'
import { LOAN_FORM_PATH } from '@src/lib/constant'
import * as types from '@src/reducers/errorReducer'
import { replace } from 'connected-next-router'
import { combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'

export const errorEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.CHANGE_ERROR),
    switchMap(() => {
      const {
        ajaxErrorResponseCode,
        display: showModalError,
        redirect: redirectError,
        requestBody,
        requestUrl,
        response
      } = state$.value.error
      const { NEW_RELIC_ENABLED, NODE_ENV } = config
      const mobile = state$.value.order?.form?.personalInformation?.mobile
      NEW_RELIC_ENABLED &&
        NODE_ENV != 'testing' &&
        window?.NREUM?.noticeError('error', {
          mobile,
          requestBody,
          requestUrl,
          response
        })
      submitEpicErrorToBugsnag({ mobile, requestBody, requestUrl, response })
      switch (ajaxErrorResponseCode) {
        case 401:
          return showModalError
            ? of(clearAuth())
            : of(changeError({ ...state$.value.error, display: true }))
        default:
          return redirectError
            ? concat(
                of(replace(LOAN_FORM_PATH.generalReject)),
                of(changeError({ ...state$.value.error, redirect: false }))
              )
            : of(changeErrorComplete())
      }
    })
  )

export const errorRedirectEpic = (action$, state$) => {
  return action$.pipe(
    ofType(types.ERROR_REDIRECT),
    switchMap(() => {
      return of(replace(decodeURIComponent(state$.value.mall.mallState.errorCallbackURI as string)))
    })
  )
}

export default combineEpics(errorEpic, errorRedirectEpic)
