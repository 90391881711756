import { InitialState, OptionActionTypes } from '@src/types/store/option'

export const FETCH_OPTIONS = 'FETCH_OPTIONS'
export const FETCH_OPTIONS_SUCCESS = 'FETCH_OPTIONS_SUCCESS'
export const FETCH_OPTIONS_FAILURE = 'FETCH_OPTIONS_FAILURE'

export const INITIAL_STATE: InitialState = {
  // minAmount: 0,
  // maxAmount: 0,
  tenorOptions: [],
  error: '',
  title: [],
  preferredContactTime: [],
  salaryMethod: [],
  dependentsCount: [],
  district: [],
  newDistrict: [],
  residentialStatus: [],
  hpcLoanPurpose: [],
  businessNature: [],
  monthlyIncomeRange: [],
  pickupAddress: [],
  disbursementBank: [],
  nationality: [],
  purposes: [],
  oswNationality: [],
  currency: [],
  countryCode: [],
  loanCompany: [],
  oswRefereeRelationship: [],
  employmentStatus: [],
  cashDropCharity: [],
  propertyStatus: [],
  propertyUsage: [],
  propertyOwnerType: [],
  educationLevel: []
}

export default function optionReducer(state = INITIAL_STATE, action: OptionActionTypes) {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload.response
      }
    case FETCH_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}
