import * as types from '@src/reducers/paymentBankReducer'

import {
  ResetPaymentFormSubmitAction,
  UpdatePaymentAccountFailureAction
} from '@src/types/store/payment-bank'

export const resetPaymentFormSubmit = (): ResetPaymentFormSubmitAction => ({
  type: types.RESET_PAYMENT_FORM_SUBMIT
})

export const updatePaymentAccountFailure = (error: string): UpdatePaymentAccountFailureAction => ({
  type: types.UPDATE_ORDER_PAYMENT_ACCOUNT_FAILURE,
  payload: { error }
})
