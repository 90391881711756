import * as appActions from '@src/actions/appActions'
import * as errorActions from '@src/actions/errorActions'
import * as optionActions from '@src/actions/optionActions'
import config from '@src/config'
import { API_ENDPOINT } from '@src/lib/constant'
import * as types from '@src/reducers/optionReducer'
import { camelizeKeys } from 'humps'
import { combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

const { WELAB_PAY_HOST } = config

export const fetchOptionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.FETCH_OPTIONS),
    switchMap(({ response }) =>
      request({
        // @FIXME: Change to use welab_host instead
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.options}/${state$.value.user.lang}`, ///v1/users/options
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid
        }
      }).pipe(
        map((data: AjaxResponse) => {
          const options = {
            ...data.response,
            ...response
          }
          return camelizeKeys(options) as unknown as Record<string, unknown>
        }),
        mergeMap((options) => {
          return [optionActions.fetchOptionsSuccess(options), appActions.changeOptionReady()]
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(optionActions.fetchOptionsFailure(error.message)),
            of(
              errorActions.changeError({
                display: true,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    )
  )

export default combineEpics(fetchOptionsEpic)
