import * as errorActions from '@src/actions/errorActions'
import * as kbaActions from '@src/actions/kbaActions'
import config from '@src/config'
import { API_ENDPOINT } from '@src/lib/constant'
import * as types from '@src/reducers/kbaReducer'
import { RootState } from '@src/reducers/rootReducer'
import { KbaActionTypes, KbaQuestion, KbaStatus } from '@src/types/store/kba'
import { camelizeKeys } from 'humps'
import { Epic, combineEpics, ofType } from 'redux-observable'
import { EMPTY, concat, interval, merge, of, timer } from 'rxjs'
import { AjaxError, AjaxResponse, ajax } from 'rxjs/ajax'
import { catchError, filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

// @TODO: MOCK Response: delete after api integration
const questions: KbaQuestion[] = [
  {
    questionId: 1,
    questionText: 'What was your residential address in Mar 2020 :',
    choices: ['None of the above', 'MK', 'CWB', 'North Point', 'Central']
  },
  {
    questionId: 5,
    questionText:
      'What is the loan amount of the loan disbursed on Jan 30, 2017 with a monthly installment of $4562 HKD?',
    choices: [
      'HKD 130000 - HKD 139999',
      'HKD 140000 - HKD 149999',
      'HKD 150000 - HKD 159999',
      'HKD 160000 - HKD 169999',
      'I do not have the mentioned loan'
    ]
  },
  {
    questionId: 2,
    questionText:
      'What is the monthly installment of the loan paid out on Apr 15, 2021 with a tenor of 6 months?',
    choices: ['HKD 2100', 'HKD 2400', 'HKD 2700', 'HKD 3000', 'I do not have the mentioned loan']
  },
  {
    questionId: 3,
    questionText: 'Have you applied for any credit card since May 15, 2021?',
    choices: ['Yes', 'No']
  },
  {
    questionId: 4,
    questionText:
      'Do you currently have any loan issued by a non-bank finance company (e.g. Prime Credit, UA Finance, etc.)?',
    choices: ['Yes', 'No']
  }
]
// @TODO: MOCK Response: delete after api integration
const kbaStatus: KbaStatus = {
  state: null,
  kbaReady: true,
  currentAttempt: 1,
  kbaFirstAttemptedAt: null,
  kbaSecondAttemptedAt: null,
  expectedQuestionCount: 0
}

const { WELAB_PAY_HOST } = config

export const dropOffKbaSessionEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.DROP_OFF_KBA),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.dropOffKba}`, ///v1/users/drop_off_kba
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        mergeMap((res: AjaxResponse) => {
          return of(kbaActions.updateKbaStatus(camelizeKeys(res.response) as unknown as KbaStatus))
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(
              errorActions.changeError({
                display: false,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            ),
            of(kbaActions.dropOffKbaFailure())
          )
        })
      )
    })
  )

export const pollKbaReadyEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.POLL_KBA_READY),
    switchMap(({ payload }) => {
      return interval(payload.interval).pipe(
        takeUntil(action$.pipe(ofType(types.POLL_KBA_READY_FINISH, types.POLL_KBA_READY_STOP))),
        switchMap(() => {
          return ajax({
            url: `${WELAB_PAY_HOST}${API_ENDPOINT.kbaQuestion}/${state$.value.user.lang}`, ///v1/users/kba_questions/
            method: 'GET',
            headers: {
              userToken: state$.value.auth.accessToken,
              applicationToken: state$.value.mall.mallState.resellerUuid,
              'Content-Type': 'application/json'
            }
          }).pipe(
            filter((res: AjaxResponse) => res.response.status.kba_ready),
            switchMap((res: AjaxResponse) => {
              return of(
                kbaActions.pollKbaReadyFinish(
                  camelizeKeys(res.response.status) as unknown as KbaStatus
                )
              )
            }),
            catchError((error: AjaxError) => {
              return of(
                errorActions.changeError({
                  display: false,
                  ajaxErrorResponseCode: error.status,
                  requestBody: JSON.stringify(error?.request?.body),
                  requestUrl: error?.request?.url,
                  response: JSON.stringify(error?.response)
                })
              )
            })
          )
        })
      )
    })
  )

export const startKbaEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.START_KBA),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.startKba}`, ///v1/users/start_kba
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        switchMap((res: AjaxResponse) => {
          if (!['manual', 'success'].includes(res.response.state))
            return concat(
              of(kbaActions.updateKbaStatus(camelizeKeys(res.response) as unknown as KbaStatus)),
              of(kbaActions.getKbaQuestions())
            )
          return of(kbaActions.updateKbaStatus(camelizeKeys(res.response) as unknown as KbaStatus))
        }),
        catchError((error: AjaxError) => {
          return of(
            errorActions.changeError({
              display: false,
              ajaxErrorResponseCode: error.status,
              requestBody: JSON.stringify(error?.request?.body),
              requestUrl: error?.request?.url,
              response: JSON.stringify(error?.response)
            })
          )
        })
      )
    })
  )

export const getKbaQuestionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_KBA_QUESTION),
    switchMap(() => {
      return interval(1000).pipe(
        takeUntil(
          merge(
            timer(20000),
            action$.ofType(types.GET_KBA_QUESTION_SUCCESS, types.GET_KBA_QUESTION_FAILURE)
          )
        ),
        switchMap(() =>
          ajax({
            url: `${WELAB_PAY_HOST}${API_ENDPOINT.kbaQuestion}/${state$.value.user.lang}`, ///v1/users/kba_questions/
            method: 'GET',
            headers: {
              userToken: state$.value.auth.accessToken,
              applicationToken: state$.value.mall.mallState.resellerUuid,
              'Content-Type': 'application/json'
            }
          }).pipe(
            switchMap((res: AjaxResponse) =>
              of(
                kbaActions.getKbaQuestionsSuccess(
                  camelizeKeys(res.response.questions) as unknown as KbaQuestion[]
                )
              )
            ),
            catchError((error: AjaxError) => {
              if (error.response.retry) return of(kbaActions.getKbaQuestionRetry())
              return concat(
                of(kbaActions.getKbaQuestionsFailure()),
                of(
                  errorActions.changeError({
                    display: false,
                    ajaxErrorResponseCode: error.status,
                    requestBody: JSON.stringify(error?.request?.body),
                    requestUrl: error?.request?.url,
                    response: JSON.stringify(error?.response)
                  })
                )
              )
            })
          )
        )
      )
    })
  )

export const submitKbaAnswerEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.SUBMIT_KBA),
    switchMap(({ payload }) => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.submitKbaAnswers}`, ///v1/users/submit_kba_answers
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          answers: {
            [payload.answer.questionId]: payload.answer.choice
          }
        }
      }).pipe(
        mergeMap((res: AjaxResponse) => {
          if (
            state$.value.kba.questions[state$.value.kba.questions.length - 1].questionId ===
            payload.answer.questionId
          ) {
            return concat(
              of(kbaActions.updateKbaStatus(camelizeKeys(res.response) as unknown as KbaStatus)),
              of(kbaActions.emptyKbaQuestion())
            )
          }
          return EMPTY
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(
              errorActions.changeError({
                display: false,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            ),
            of(kbaActions.submitKbaQuestionFailure())
          )
        })
      )
    })
  )

// export const submitKbaAnswerEpic = (action$) =>
//   action$.pipe(
//     ofType(types.SUBMIT_KBA),
//     switchMap(({ payload }) => {
//       const kbaStatus: KbaStatus = {
//         state: 'all_attempted',
//         kbaReady: true,
//         currentAttempt: 1,
//         kbaFirstAttemptedAt: null,
//         kbaSecondAttemptedAt: null,
//         expectedQuestionCount: 0
//       }
//       // @TODO: Call payment api for integration
//       // POST ${WELAB_PAY_HOST}/v1/users/submit_kba_answers
//       if (payload.answer.questionId === 4) {
//         return of(kbaActions.updateKbaStatus(camelizeKeys(kbaStatus) as KbaStatus))
//       }
//       return EMPTY
//     })
//   )

export default combineEpics(
  pollKbaReadyEpic,
  startKbaEpic,
  getKbaQuestionsEpic,
  submitKbaAnswerEpic,
  dropOffKbaSessionEpic
)
