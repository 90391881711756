import { KbaActionTypes, KbaState } from '@src/types/store/kba'

import { merge } from 'lodash'

// KBA Question fetching
export const GET_KBA_QUESTION = 'GET_KBA_QUESTION'
export const GET_KBA_QUESTION_SUCCESS = 'GET_KBA_QUESTION_SUCCESS'
export const GET_KBA_QUESTION_FAILURE = 'GET_KBA_QUESTION_FAILURE'
export const GET_KBA_QUESTION_RETRY = 'GET_KBA_QUESTION_RETRY'
// Finish answering
export const SUBMIT_KBA = 'SUBMIT_KBA'
export const SUBMIT_KBA_SUCCESS = 'SUBMIT_KBA_SUCCESS'
export const SUBMIT_KBA_FAILURE = 'SUBMIT_KBA_FAILURE'
// KBA status
export const POLL_KBA_READY = 'POLL_KBA_READY'
export const POLL_KBA_READY_STOP = 'POLL_KBA_READY_STOP'
export const POLL_KBA_READY_FINISH = 'POLL_KBA_READY_FINISH'
export const START_KBA = 'START_KBA'
export const KBA_TIMES_UP = 'KBA_TIMES_UP'
export const DROP_OFF_KBA = 'DROP_OFF_KBA'
export const DROP_OFF_KBA_FAILURE = 'DROP_OFF_KBA_FAILURE'
export const EMPTY_KBA_QUESTIONS = 'EMPTY_KBA_QUESTIONS'

export const UPDATE_KBA_STATUS = 'UPDATE_KBA_STATUS'

export const INITIAL_STATE: KbaState = {
  questions: [],
  status: {
    state: null,
    kbaReady: false,
    currentAttempt: 0,
    kbaFirstAttemptedAt: null,
    kbaSecondAttemptedAt: null,
    expectedQuestionCount: 0
  },
  timestamp: '2021-06-14T16:13:29.152+08:00',
  apiVersion: 'welab_pay_api_v1'
}

export default function kbaReducer(
  state: KbaState = INITIAL_STATE,
  action: KbaActionTypes
): KbaState {
  switch (action.type) {
    case GET_KBA_QUESTION_SUCCESS:
      return { ...merge(state, { questions: action.payload.questions }) }
    case UPDATE_KBA_STATUS:
      return {
        ...merge(state, {
          status: {
            state: action.payload.kbaStatus.state,
            kbaReady: action.payload.kbaStatus.kbaReady,
            currentAttempt: action.payload.kbaStatus.currentAttempt,
            kbaFirstAttemptedAt: action.payload.kbaStatus.kbaFirstAttemptedAt,
            kbaSecondAttemptedAt: action.payload.kbaStatus.kbaSecondAttemptedAt,
            expectedQuestionCount: action.payload.kbaStatus.expectedQuestionCount
          }
        })
      }
    case POLL_KBA_READY_FINISH:
      return { ...merge(state, { status: action.payload.kbaStatus }) }
    case EMPTY_KBA_QUESTIONS: {
      const cloned = { ...merge(state) }
      cloned.questions = []
      return cloned
    }

    default:
      return state
  }
}
