import * as errorActions from '@src/actions/errorActions'
import * as orderActions from '@src/actions/orderActions'
import * as signOTPActions from '@src/actions/signOTPActions'
import config from '@src/config'
import { ORDER_TYPE } from '@src/lib/constant'
import * as types from '@src/reducers/signOTPReducer'
import { OrderInfo } from '@src/types/store/order'
import { replace } from 'connected-next-router'
import { camelizeKeys } from 'humps'
import { combineEpics, ofType } from 'redux-observable'
import { concat, iif, of } from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

const { WELAB_PAY_HOST } = config

export const checkSignAgreementOTPEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.CHECK_SIGN_AGREEMENT_OTP),
    switchMap(({ payload }) => {
      const otps = Object.values(payload.otpInformation)
      const verificationCode = otps.join('')
      const {
        order: { orderId }
      } = state$.value.order

      return request({
        url: `${WELAB_PAY_HOST}/v1/users/orders/${orderId}/confirm`,
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          verification_code: verificationCode
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as OrderInfo
        }),
        mergeMap((order: OrderInfo) => {
          return concat(
            of(signOTPActions.checkSignAgreementOTPSuccess()),
            of(orderActions.getOrderInfoSuccess(order)),
            of(signOTPActions.triggerWaitingOTPFeedbackComplete())
          )
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(
              errorActions.changeError({
                display: false,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            ),
            of(signOTPActions.checkSignAgreementOTPFailure()),
            of(orderActions.getOrderInfo(orderId))
          )
        })
      )
    })
  )

export const getSignAgreementOTPEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_SIGN_AGREEMENT_OTP),
    switchMap(() => {
      const {
        agreement: { agreementRead },
        order: { orderId }
      } = state$.value.order
      return request({
        url: `${WELAB_PAY_HOST}/v1/users/orders/${orderId}/send_sms_with_otp`,
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          agreement_explanation_received: agreementRead
        }
      }).pipe(
        mergeMap(() => {
          return concat(of(signOTPActions.getSignAgreementOTPSuccess()))
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(
              errorActions.changeError({
                display: true,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            ),
            of(signOTPActions.getSignAgreementOTPFailure())
          )
        })
      )
    })
  )

export default combineEpics(checkSignAgreementOTPEpic, getSignAgreementOTPEpic)
