import config from '@src/config'
import { i18n } from '@src/lib/i18n'
import {
  Flex,
  Grid,
  GridItem,
  Text,
  Title,
  mediaQueries
} from '@welend/apple-shopping-cart-ui-library'
import React from 'react'
import styled from 'styled-components'

const WarningContainer = styled(Flex)`
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  padding: 0 24px;
  background-color: #f5f5f7;
  min-height: 388px;
  @media ${mediaQueries.desktop} {
    padding: 0;
    min-height: 481px;
  },
`

const Warning = (): JSX.Element => {
  const { WELAB_PHONE_LINK, WELAB_EMAIL } = config
  return (
    <WarningContainer
      column
      alignItems="center"
      justifyContent="flex-start"
      desktopJustifyContent="flex-start">
      <Grid mt="8" desktopMT="14" justifyContent="center">
        <GridItem textAlign="center" style={{ width: '75%', margin: 'auto' }}>
          <Title align="center" typeface="small" fontWeight="bold" desktopCenter>
            {i18n.t('error:warning_title')}
          </Title>
          <Title mt={4} align="center" desktopCenter>
            {i18n.t('error:warning_stop_serveice')}
          </Title>
          <Text
            mt={4}
            align="center"
            typeface="large"
            fontWeight="normal"
            desktopCenter
            dangerouslySetInnerHTML={{
              __html: i18n.t('error:warning_welab_bank_serveice', {
                telephone: WELAB_PHONE_LINK,
                email: WELAB_EMAIL
              })
            }}
          />
        </GridItem>
      </Grid>
    </WarningContainer>
  )
}

Warning.getInitialProps = async () => {
  const progress = {
    currentStep: '',
    stepNames: [],
    title: ''
  }
  return { progress }
}

export default Warning
