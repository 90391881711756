import { combineEpics } from 'redux-observable'
import appEpic from './appEpic'
import authEpic from './authEpic'
import documentEpic from './documentEpic'
import errorEpic from './errorEpic'
import kbaEpic from './kbaEpic'
import mallEpic from './mallEpic'
import optionEpic from './optionEpic'
import orderEpic from './orderEpic'
import signOTPEpic from './signOTPEpic'
import userEpic from './userEpic'

export default combineEpics(
  appEpic,
  authEpic,
  userEpic,
  optionEpic,
  orderEpic,
  kbaEpic,
  signOTPEpic,
  mallEpic,
  documentEpic,
  errorEpic
)
