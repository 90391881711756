import * as mallActions from '@src/actions/mallAction'
import * as mallTypes from '@src/reducers/mallReducer'
import { push } from 'connected-next-router'
import { camelizeKeys } from 'humps'
import querystring from 'querystring'
import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

const mapUrlHashToState = (hash: string): any => {
  const queries = querystring.parse(hash.substring(1))
  const camelizedQuery = camelizeKeys(queries)

  return {
    ...camelizedQuery
  }
}

export const mallReadyEpic = (action$, state$) => {
  return action$.pipe(
    ofType(mallTypes.CHANGE_MALL_READY),
    map(() => {
      return mallActions.changeMallAction(state$.value.auth.state)
    })
  )
}
export default combineEpics(mallReadyEpic)
