import config from '@src/config'
import { initialRouterState, routerReducer } from 'connected-next-router'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import appReducer, { INITIAL_STATE as appState } from './appReducer'
import authReducer, { INITIAL_STATE as authState } from './authReducer'
import documentReducer, { INITIAL_STATE as documentState } from './documentReducer'
import errorReducer, { INITIAL_STATE as errorState } from './errorReducer'
import kbaReducer, { INITIAL_STATE as kbaState } from './kbaReducer'
import mallReducer, { INITIAL_STATE as mallState } from './mallReducer'
import optionReducer, { INITIAL_STATE as optionState } from './optionReducer'
import orderReducer, { INITIAL_STATE as orderState } from './orderReducer'
import paymentBankReducer, { INITIAL_STATE as paymentBankState } from './paymentBankReducer'
import progressReducer, { INITIAL_STATE as progressState } from './progressReducer'
import signOTPReducer, { INITIAL_STATE as signOTPState } from './signOTPReducer'

import userReducer, { INITIAL_STATE as userState } from './userReducer'

export const rootState = {
  app: appState,
  auth: authState,
  error: errorState,
  kba: kbaState,
  mall: mallState,
  option: optionState,
  order: orderState,
  router: initialRouterState(config.APP_HOST),
  document: documentState,
  user: userState,
  paymentBank: paymentBankState,
  signOTP: signOTPState,
  progress: progressState
}
export type RootState = ReturnType<typeof rootReducer>
export type EmptyState = ReturnType<typeof Object>

const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
  blacklist: ['tempToken', 'withToken', 'tempTokenStatus', 'tempTokenExpireAt']
}

const mallPersistConfig = {
  key: 'mall',
  storage: storageSession
}

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  error: errorReducer,
  kba: kbaReducer,
  mall: mallReducer,
  option: optionReducer,
  order: orderReducer,
  router: routerReducer,
  user: userReducer,
  document: documentReducer,
  paymentBank: paymentBankReducer,
  signOTP: signOTPReducer,
  progress: progressReducer
})

export default rootReducer
