import * as appActions from '@src/actions/appActions'
import { clearAuth } from '@src/actions/authActions'
import * as errorActions from '@src/actions/errorActions'
import * as orderActions from '@src/actions/orderActions'
import * as paymentBankActions from '@src/actions/paymentBankActions'
import * as userActions from '@src/actions/userActions'
import config from '@src/config'
import { API_ENDPOINT, LOAN_FORM_PATH } from '@src/lib/constant'
import { toString } from '@src/lib/helper'
import { i18n } from '@src/lib/i18n'
import * as types from '@src/reducers/orderReducer'
import { Error } from '@src/types/store/error'
import {
  Agreement,
  CompleteOnboard,
  OrderInfo,
  PaymentBankInformation,
  PersonalInformation,
  ResidentialInformation,
  ReviewInformation,
  UserProfile,
  Workflow
} from '@src/types/store/order'
import { push, replace } from 'connected-next-router'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { find, get } from 'lodash'
import { combineEpics, ofType } from 'redux-observable'
import { concat, of, timer } from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

const { WELAB_PAY_HOST } = config

export const getPersonalInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_PERSONAL_INFO),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.profile}`, //'/v1/users/onboarding/profile'
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid
        }
      }).pipe(
        map((data: AjaxResponse) => {
          const userProfile = toString(camelizeKeys(data.response) as unknown as UserProfile)
          const lang = data.response.language
          return { userProfile, lang }
        }),
        mergeMap(({ userProfile, lang }) => {
          //For mouseflow tag
          window.user = userProfile
          return concat(
            of(
              orderActions.getPersonalInfoSuccess(userProfile),
              orderActions.updateOrderResidentialInfoSuccess(userProfile),
              orderActions.updatePaymentAccountSuccess(userProfile),
              paymentBankActions.resetPaymentFormSubmit(),
              userActions.updateLangSuccess(lang),
              appActions.changeOrder(),
              orderActions.getWorkFlow()
            )
          )
        }),
        catchError((error: AjaxError) => {
          return of(
            errorActions.changeError({
              display: true,
              ajaxErrorResponseCode: error.status,
              requestBody: JSON.stringify(error?.request?.body),
              requestUrl: error?.request?.url,
              response: JSON.stringify(error?.response)
            })
          )
        })
      )
    })
  )

export const getWorkFlowEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_WORKFLOW),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.workflow}`, ///v1/users/workflow
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as Workflow
        }),
        mergeMap((workflow: Workflow) => {
          return of(orderActions.getWorkFlowSuccess(workflow))
        }),
        catchError((error: AjaxError) => {
          return of(
            errorActions.changeError({
              display: true,
              ajaxErrorResponseCode: error.status,
              requestBody: JSON.stringify(error?.request?.body),
              requestUrl: error?.request?.url,
              response: JSON.stringify(error?.response)
            })
          )
        })
      )
    })
  )

export const getDeclarationVersionEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_DECLARATION_VERSION),
    switchMap(() => {
      const declarationContent = []
      for (let i = 1; i <= 9; i++) {
        declarationContent.push(i18n.t(`declaration_${i}`))
      }

      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.declarationVersion}`, // /v1/users/declaration_version
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          content: encodeURI(declarationContent.join(';'))
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return data.response.id.toString()
        }),
        mergeMap((id: string) => {
          return concat(
            of(orderActions.getDeclarationVersionSuccess(id)),
            of(appActions.changeDeclarationReady())
          )
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(orderActions.getDeclarationVersionFailure(error.message)),
            of(
              errorActions.changeError({
                display: true,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const updateOrderPersonalInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_ORDER_PERSONAL_INFO),
    switchMap(({ payload }) => {
      const { personalInformation } = payload
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.personalInfo}`, // /v1/users/personal_info
        method: 'PUT',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          ...decamelizeKeys(personalInformation)
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as PersonalInformation
        }),
        mergeMap((pi: PersonalInformation) => {
          return concat(
            of(orderActions.updateOrderPersonalInfoSuccess(pi)),
            of(orderActions.getWorkFlow())
          )
        }),
        catchError((error: AjaxError) => {
          let errorDetail: Error = { display: true, ajaxErrorResponseCode: error.status }
          // const isProfileExisting = error.response?.errors === 'profile already exists'
          // const isDisbursementAccount = error.response?.errors?.disbursement_account
          const isLinkedAnotherUser = error.response?.find((x) => x.code === 'pi001')
          // if (isProfileExisting) {
          //   errorDetail = {
          //     ...errorDetail,
          //     display: true,
          //     message: i18n.t('error:hkid_used'),
          //     button: i18n.t('common:button_close'),
          //     contacts: i18n.t('error:whatsapp_hkid_used'),
          //     gaTagEventLabel: 'existing_hkid'
          //   }
          // } else if (isDisbursementAccount) {
          //   errorDetail = {
          //     ...errorDetail,
          //     display: true,
          //     message: i18n.t('aip:pop_up_form_errors_disbursement_account_invalid'),
          //     button: i18n.t('common:button_back'),
          //     contacts: i18n.t('error:whatsapp_bank_used'),
          //     gaTagEventLabel: 'bank_account_validation'
          //   }
          // } else
          if (isLinkedAnotherUser) {
            errorDetail = {
              ...errorDetail,
              display: true,
              message: i18n.t('error:hkid_used'),
              button: i18n.t('common:button_close'),
              buttonCallBackList: [clearAuth(), errorActions.triggerErrorRedirect()],
              contacts: i18n.t('error:whatsapp_hkid_used'),
              gaTagEventLabel: 'existing_hkid'
            }
          }
          return concat(
            of(orderActions.updateOrderPersonalInfoFailure(error.message)),
            of(
              errorActions.changeError({
                ...errorDetail,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const updateOrderResidentialInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_ORDER_RESIDENTIAL_INFO),
    switchMap(({ payload }) => {
      const { residentialInformation } = payload
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.residentialInfo}`, // /v1/users/residential_info
        method: 'PUT',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          ...decamelizeKeys(residentialInformation)
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as ResidentialInformation
        }),
        mergeMap((ri: ResidentialInformation) => {
          const monthlyIncomeRange = get(state$, 'value.option.monthlyIncomeRange', [])
          const range = [ri.monthlyIncomeLowerBound, ri.monthlyIncomeUpperBound]
          const incomeRange = find(monthlyIncomeRange, ['name', range])
          ri['monthlyIncomeRange'] = incomeRange.id

          return concat(
            of(orderActions.updateOrderResidentialInfoSuccess(ri)),
            of(orderActions.getWorkFlow())
          )
        }),
        catchError((error: AjaxError) => {
          const errorDetail = {
            display: false,
            ajaxErrorResponseCode: error.status
          }
          return concat(
            of(orderActions.updateOrderResidentialInfoFailure(error.message)),
            of(
              errorActions.changeError({
                ...errorDetail,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const pollWorkFlowEpic = (action$) =>
  action$.pipe(
    ofType(types.POLL_WORK_FLOW),
    switchMap(({ payload }) => {
      const { interval } = payload
      return timer(0, interval).pipe(
        takeUntil(action$.pipe(ofType(types.POLL_WORK_FLOW_STOP))),
        map(() => orderActions.getWorkFlow())
      )
    })
  )

export const triggerTencentsTestEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.TRIGGER_TENCENTS_TEST),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.triggerTencentsTest}`, ///v1/users/trigger_tencents_test
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        mergeMap(() => {
          return concat(of(orderActions.triggerTencentsTestSuccess()))
        }),
        catchError((error: AjaxError) => {
          const errorDetail = {
            display: false,
            ajaxErrorResponseCode: error.status
          }
          return concat(
            of(orderActions.triggerTencentsTestFailure()),
            of(
              errorActions.changeError({
                ...errorDetail,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const updatePaymentAccountEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_ORDER_PAYMENT_ACCOUNT),
    switchMap(({ payload }) => {
      const { paymentBankInformation } = payload
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.paymentDetails}`, ///v1/users/payment_details
        method: 'PUT',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          ...decamelizeKeys(paymentBankInformation)
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as PaymentBankInformation
        }),
        mergeMap((paymentInformation: PaymentBankInformation) => {
          return concat(
            of(
              orderActions.updatePaymentAccountSuccess(paymentInformation),
              orderActions.triggerTencentsTest(),
              orderActions.getWorkFlow()
            )
          )
        }),
        catchError((error: AjaxError) => {
          const errorDetail = {
            display: false,
            ajaxErrorResponseCode: error.status,
            requestBody: JSON.stringify(error?.request?.body),
            requestUrl: error?.request?.url,
            response: JSON.stringify(error?.response)
          }
          return concat(
            of(paymentBankActions.updatePaymentAccountFailure(error.message)),
            of(orderActions.updateOrderError(error.message)),
            of(
              errorActions.changeError({
                ...errorDetail
              })
            )
          )
        })
      )
    })
  )

export const updateOrderReviewInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_ORDER_REVIEW_INFO),
    switchMap(({ payload }) => {
      const { reviewInformation } = payload
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.apply}`, ///v1/users/apply
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          ...decamelizeKeys(reviewInformation)
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as UserProfile
        }),
        mergeMap((userProfile: UserProfile) => {
          return concat(
            of(orderActions.updateOrderReviewInfoSuccess(reviewInformation, userProfile)),
            of(orderActions.updateHasApplied(true)),
            of(orderActions.getWorkFlow())
          )
        }),
        catchError((error: AjaxError) => {
          const errorDetail = {
            redirect: true,
            ajaxErrorResponseCode: error.status,
            requestBody: JSON.stringify(error?.request?.body),
            requestUrl: error?.request?.url,
            response: JSON.stringify(error?.response)
          }
          return concat(
            of(orderActions.updateOrderReviewInfoFailure(error.message)),
            of(errorActions.changeError({ ...errorDetail }))
          )
        })
      )
    })
  )

export const getOrderInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_ORDER_INFO),
    switchMap(({ payload }) => {
      const { orderId } = payload
      return request({
        url: `${WELAB_PAY_HOST}/v1/users/orders/${orderId}`,
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as OrderInfo
        }),
        mergeMap((order: OrderInfo) => {
          return concat(of(orderActions.getOrderInfoSuccess(order)))
        }),
        catchError((error: AjaxError) => {
          const errorDetail = { display: true, ajaxErrorResponseCode: error.status }
          return concat(
            of(
              errorActions.changeError({
                ...errorDetail,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const getOrderAgreementEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.GET_ORDER_AGREEMENT),
    switchMap(({ payload }) => {
      const { orderId } = payload
      return request({
        url: `${WELAB_PAY_HOST}/v1/users/orders/${orderId}/agreement`,
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as Agreement
        }),
        mergeMap((agreement: Agreement) => {
          return concat(of(orderActions.getOrderAgreementSuccess(agreement)))
        }),
        catchError((error: AjaxError) => {
          const errorDetail = {
            display: true,
            requestBody: JSON.stringify(error?.request?.body),
            requestUrl: error?.request?.url,
            response: JSON.stringify(error?.response)
          }
          return concat(of(errorActions.changeError({ ...errorDetail })))
        })
      )
    })
  )

export const setAgreementAudioListenedEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.SET_AGREEMENT_AUDIO_LISTENED),
    switchMap(({ payload }) => {
      const { orderId } = payload
      return request({
        url: `${WELAB_PAY_HOST}/v1/users/orders/${orderId}/listen_agreement_audio`,
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        }
      }).pipe(
        mergeMap(() => {
          return of(orderActions.setAgreementAudioListenedSuccess())
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(orderActions.setAgreementAudioListenedFailure()),
            of(
              errorActions.changeError({
                display: true,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const updateCompleteBoardEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_COMPLETE_BOARD),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.completeOnboard}`, //complete_onboard
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return camelizeKeys(data.response) as unknown as CompleteOnboard
        }),
        mergeMap((completeOnboard: CompleteOnboard) => {
          return concat(
            of(orderActions.UpdateCompleteBoardSuccess(completeOnboard)),
            of(orderActions.updateTuPulledWithin14Days())
          )
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(push(LOAN_FORM_PATH.generalReject)),
            of(
              errorActions.changeError({
                display: false,
                ajaxErrorResponseCode: error.status,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const successRedirectEpic = (action$, state$) => {
  return action$.pipe(
    ofType(types.SUCCESS_REDIRECT),
    switchMap(() => {
      return of(replace(decodeURIComponent(state$.value.mall.mallState.redirectURI as string)))
    })
  )
}

export const submitOrderCheckoutEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.SUBMIT_ORDER_CHECKOUT),
    switchMap(({ payload }) => {
      const { orderId } = payload
      return request({
        url: `${WELAB_PAY_HOST}/v1/users/orders/${orderId}/checkout`,
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          customer_declaration_id: Number(state$.value.order.declarationVersion)
        }
      }).pipe(
        mergeMap(() => {
          return concat(
            of(orderActions.submitOrderCheckoutSuccess()),
            of(orderActions.triggerSuccessRedirect())
          )
        }),
        catchError((error: AjaxError) => {
          return concat(
            of(orderActions.submitOrderCheckoutFailure()),
            of(
              errorActions.changeError({
                display: true,
                requestBody: JSON.stringify(error?.request?.body),
                requestUrl: error?.request?.url,
                response: JSON.stringify(error?.response)
              })
            )
          )
        })
      )
    })
  )

export const updateTuPulledWithin14DaysEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPDATE_TU_PULLED_WITHIN_14_DAYS),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.profile}`, ///v1/users/onboarding/profile
        method: 'GET',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid
        }
      }).pipe(
        map((data: AjaxResponse) => {
          return toString(camelizeKeys(data.response) as unknown as UserProfile)
        }),
        mergeMap((pi: UserProfile) => {
          return concat(
            of(
              orderActions.updateTuPulledWithin14DaysSuccess(
                (pi as PersonalInformation).tuPulledWithin14Days
              )
            ),
            of(orderActions.getWorkFlow())
          )
        }),
        catchError((error: AjaxError) => {
          return of(
            errorActions.changeError({
              display: true,
              ajaxErrorResponseCode: error.status,
              requestBody: JSON.stringify(error?.request?.body),
              requestUrl: error?.request?.url,
              response: JSON.stringify(error?.response)
            })
          )
        })
      )
    })
  )

export const createMerchantCreditLineEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.CREATE_MERCHANT_CREDIT_LINE),
    switchMap(() => {
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.createMerchantCreditLine}`,
        method: 'POST',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid
        }
      }).pipe(
        map((data: AjaxResponse) => {
          // update user profile after credit line -> re render auth
          const userProfile = toString(camelizeKeys(data.response) as unknown as UserProfile)
          return { userProfile }
        }),
        mergeMap(({ userProfile }) => {
          return concat(
            of(
              orderActions.getPersonalInfoSuccess(userProfile),
              orderActions.updateHasApplied(true),
              orderActions.getWorkFlow()
            )
          )
        }),
        catchError((error: AjaxError) => {
          return of(
            errorActions.changeError({
              display: true,
              ajaxErrorResponseCode: error.status,
              requestBody: JSON.stringify(error?.request?.body),
              requestUrl: error?.request?.url,
              response: JSON.stringify(error?.response)
            })
          )
        })
      )
    })
  )

export default combineEpics(
  createMerchantCreditLineEpic,
  updateOrderPersonalInfoEpic,
  updateOrderResidentialInfoEpic,
  updateOrderReviewInfoEpic,
  updatePaymentAccountEpic,
  getWorkFlowEpic,
  getPersonalInfoEpic,
  pollWorkFlowEpic,
  getOrderInfoEpic,
  getOrderAgreementEpic,
  getDeclarationVersionEpic,
  updateCompleteBoardEpic,
  setAgreementAudioListenedEpic,
  successRedirectEpic,
  submitOrderCheckoutEpic,
  updateTuPulledWithin14DaysEpic
)
