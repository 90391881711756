import * as types from '@src/reducers/kbaReducer'
import {
  DropOffKbaAction,
  DropOffKbaFailureAction,
  EmptyKbaQuestionsAction,
  GetKbaQuestionRetryAction,
  GetKbaQuestionsAction,
  GetKbaQuestionsFailureAction,
  GetKbaQuestionsSuccessAction,
  KbaAnswer,
  KbaQuestion,
  KbaStatus,
  PollKbaReadyAction,
  PollKbaReadyFinishAction,
  PollKbaReadyStopAction,
  StartKbaAction,
  SubmitKbaAction,
  SubmitKbaFailureAction,
  UpdateKbaStatusAction
} from '@src/types/store/kba'

export const getKbaQuestions = (): GetKbaQuestionsAction => ({
  type: types.GET_KBA_QUESTION
})

export const getKbaQuestionsSuccess = (questions: KbaQuestion[]): GetKbaQuestionsSuccessAction => ({
  type: types.GET_KBA_QUESTION_SUCCESS,
  payload: {
    questions
  }
})

export const getKbaQuestionsFailure = (): GetKbaQuestionsFailureAction => ({
  type: types.GET_KBA_QUESTION_FAILURE
})

export const submitKbaQuestion = (answer: KbaAnswer): SubmitKbaAction => ({
  type: types.SUBMIT_KBA,
  payload: {
    answer
  }
})

export const submitKbaQuestionFailure = (): SubmitKbaFailureAction => ({
  type: types.SUBMIT_KBA_FAILURE
})

export const getKbaQuestionRetry = (): GetKbaQuestionRetryAction => ({
  type: types.GET_KBA_QUESTION_RETRY
})

export const dropOffCurrentKbaSession = (): DropOffKbaAction => ({
  type: types.DROP_OFF_KBA
})

export const dropOffKbaFailure = (): DropOffKbaFailureAction => ({
  type: types.DROP_OFF_KBA_FAILURE
})

export const emptyKbaQuestion = (): EmptyKbaQuestionsAction => ({
  type: types.EMPTY_KBA_QUESTIONS
})

export const pollKbaReady = (interval: number): PollKbaReadyAction => ({
  type: types.POLL_KBA_READY,
  payload: {
    interval
  }
})

export const pollKbaReadyStop = (): PollKbaReadyStopAction => {
  return {
    type: types.POLL_KBA_READY_STOP
  }
}

export const pollKbaReadyFinish = (kbaStatus: KbaStatus): PollKbaReadyFinishAction => {
  return {
    type: types.POLL_KBA_READY_FINISH,
    payload: {
      kbaStatus
    }
  }
}

export const startKba = (): StartKbaAction => ({
  type: types.START_KBA
})

export const updateKbaStatus = (kbaStatus: KbaStatus): UpdateKbaStatusAction => ({
  type: types.UPDATE_KBA_STATUS,
  payload: {
    kbaStatus
  }
})
