import * as appActions from '@src/actions/appActions'
import * as documentActions from '@src/actions/documentActions'
import * as errorActions from '@src/actions/errorActions'
import config from '@src/config'
import { API_ENDPOINT, DOCUMENT_TYPE } from '@src/lib/constant'
import { i18n } from '@src/lib/i18n'
import { compressImage, getImageDimensions } from '@src/lib/image'
import * as types from '@src/reducers/documentReducer'
import { UploadProofAction } from '@src/types/store/document'
import { camelizeKeys } from 'humps'
import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

const {
  WELAB_PAY_HOST,
  DIMENSION_HKID_MIN_WIDTH,
  DIMENSION_HKID_MIN_HEIGHT,
  DIMENSION_MAX_WIDTH,
  DIMENSION_MAX_HEIGHT,
  DIMENSION_COMPRESSED,
  FILE_SIZE_MIN,
  FILE_SIZE_MAX
} = config

const exceedDocMaxSize = (width: number, height: number, file: File) => {
  return width > DIMENSION_MAX_WIDTH || height > DIMENSION_MAX_HEIGHT || file.size > FILE_SIZE_MAX
}

const belowHkidMinSize = (width: number, height: number, file: File) => {
  return (
    (width < DIMENSION_HKID_MIN_WIDTH && height < DIMENSION_HKID_MIN_HEIGHT) ||
    file.size < FILE_SIZE_MIN
  )
}

export const uploadProofEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.UPLOAD_PROOF),
    switchMap((action: UploadProofAction) => {
      if (action.payload.file.type.startsWith('image/')) {
        return from(getImageDimensions(action.payload.file)).pipe(
          map((dimensions) => ({ ...action.payload, ...dimensions }))
        )
      }
      return of(action.payload)
    }),
    switchMap(({ width, height, file, documentType }) => {
      if (!width || !height) {
        // Non image file will have no width or height detected
        return of({ file, documentType, error: null })
      }

      if (exceedDocMaxSize(width, height, file)) {
        return from(compressImage(file, DIMENSION_COMPRESSED)).pipe(
          map((compressedImage) => ({ documentType, file: compressedImage }))
        )
      }

      if (documentType === DOCUMENT_TYPE.hkid) {
        if (belowHkidMinSize(width, height, file)) {
          return of({ file, documentType, error: new Error() })
        }
      }

      return of({ file, documentType, error: null })
    }),
    switchMap(({ file, documentType, error }) => {
      if (error) {
        let gaTagEventLabel = 'upload_file_size'
        if (documentType === DOCUMENT_TYPE.hkid) {
          gaTagEventLabel = 'hkid_file_size'
        }
        return concat(
          of(documentActions.uploadProofFailure()),
          of(
            errorActions.changeError({
              display: true,
              message: i18n.t('errors_file_size_invalid'),
              gaTagEventLabel: gaTagEventLabel,
              ajaxErrorResponseCode: error.status
            })
          )
        )
      }
      const { tempToken, withToken } = state$.value.auth
      let requestConfig: AjaxRequest = {
        url: '',
        method: 'POST'
      }
      const formData = new FormData()
      formData.append('doc_type', documentType)
      formData.append('file', file)

      if (withToken) {
        requestConfig = {
          ...requestConfig,
          url: `${WELAB_PAY_HOST}${API_ENDPOINT.uploadProofsViaQr}` ///v1/users/upload_proofs_via_qr
        }
        formData.append('token', tempToken)
      } else {
        requestConfig = {
          ...requestConfig,
          url: `${WELAB_PAY_HOST}${API_ENDPOINT.uploadProofs}`, // /v1/users/upload_proofs
          headers: {
            userToken: state$.value.auth.accessToken,
            applicationToken: state$.value.mall.mallState.resellerUuid
          }
        }
      }
      return concat(
        of(appActions.changeLoading({ isShow: true })),
        request({
          ...requestConfig,
          body: formData
        }).pipe(
          mergeMap((data: AjaxResponse) => {
            return [
              documentActions.uploadProofSuccess(camelizeKeys(data.xhr.response) as any),
              appActions.changeLoading({ isShow: false })
            ]
          }),
          catchError((error: AjaxError) => {
            return concat(
              of(documentActions.uploadProofFailure()),
              of(appActions.changeLoading({ isShow: false })),
              of(
                errorActions.changeError({
                  display: true,
                  title: i18n.t('error:hkid_title'),
                  message: i18n.t('error:hkid'),
                  button: i18n.t('common:button_retry'),
                  ajaxErrorResponseCode: error.status,
                  requestBody: JSON.stringify(error?.request?.body),
                  requestUrl: error?.request?.url,
                  response: JSON.stringify(error?.response)
                })
              )
            )
          })
        )
      )
    })
  )

export default combineEpics(uploadProofEpic)
