import { SignOTPActionTypes, SignOTPState } from '@src/types/store/sign-otp'
import { merge } from 'lodash'

export const CHECK_SIGN_AGREEMENT_OTP = 'CHECK_SIGN_AGREEMENT_OTP'
export const CHECK_SIGN_AGREEMENT_OTP_SUCCESS = 'CHECK_SIGN_AGREEMENT_OTP_SUCCESS'
export const CHECK_SIGN_AGREEMENT_OTP_FAILURE = 'CHECK_SIGN_AGREEMENT_OTP_FAILURE'
export const RESET_OTP_FAILURE_FLAG = 'RESET_OTP_FAILURE_FLAG'

export const GET_SIGN_AGREEMENT_OTP = 'GET_SIGN_AGREEMENT_OTP'
export const GET_SIGN_AGREEMENT_OTP_SUCCESS = 'GET_SIGN_AGREEMENT_OTP_SUCCESS'
export const GET_SIGN_AGREEMENT_OTP_FAILURE = 'GET_SIGN_AGREEMENT_OTP_FAILURE'

export const WAITING_OTP_FEEDBACK = 'WAITING_OTP_FEEDBACK'
export const WAITING_OTP_FEEDBACK_COMPLETE = 'WAITING_OTP_FEEDBACK_COMPLETE'

export const INITIAL_STATE: SignOTPState = {
  isFailure: false,
  isLoading: false
}

export default function signOTPReducer(
  state: SignOTPState = INITIAL_STATE,
  action: SignOTPActionTypes
): SignOTPState {
  switch (action.type) {
    case CHECK_SIGN_AGREEMENT_OTP_FAILURE:
      return { ...merge(state, { isFailure: true, isLoading: false }) }
    case RESET_OTP_FAILURE_FLAG:
      return { ...merge(state, { isFailure: false, isLoading: false }) }
    case WAITING_OTP_FEEDBACK:
      return { ...merge(state, { isLoading: true }) }
    case WAITING_OTP_FEEDBACK_COMPLETE:
      return { ...merge(state, { isLoading: false }) }
    default:
      return state
  }
}
