import * as types from '@src/reducers/signOTPReducer'

import {
  CheckSignAgreementOTPAction,
  CheckSignAgreementOTPFailureAction,
  CheckSignAgreementOTPSuccessAction,
  GetSignAgreementOTPAction,
  GetSignAgreementOTPFailureAction,
  GetSignAgreementOTPSuccessAction,
  OtpInformation,
  ResetOTPFailureFlag,
  WaitingOTPFeedbackAction,
  WaitingOTPFeedbackCompleteAction
} from '@src/types/store/sign-otp'

export const checkSignAgreementOTP = (
  otpInformation: OtpInformation
): CheckSignAgreementOTPAction => ({
  type: types.CHECK_SIGN_AGREEMENT_OTP,
  payload: { otpInformation }
})

export const checkSignAgreementOTPSuccess = (): CheckSignAgreementOTPSuccessAction => ({
  type: types.CHECK_SIGN_AGREEMENT_OTP_SUCCESS
})

export const checkSignAgreementOTPFailure = (): CheckSignAgreementOTPFailureAction => ({
  type: types.CHECK_SIGN_AGREEMENT_OTP_FAILURE
})

export const resetOTPFailureFlag = (): ResetOTPFailureFlag => ({
  type: types.RESET_OTP_FAILURE_FLAG
})

export const getSignAgreementOTP = (): GetSignAgreementOTPAction => ({
  type: types.GET_SIGN_AGREEMENT_OTP
})

export const getSignAgreementOTPSuccess = (): GetSignAgreementOTPSuccessAction => ({
  type: types.GET_SIGN_AGREEMENT_OTP_SUCCESS
})

export const getSignAgreementOTPFailure = (): GetSignAgreementOTPFailureAction => ({
  type: types.GET_SIGN_AGREEMENT_OTP_FAILURE
})

export const triggerWaitingOTPFeedback = (): WaitingOTPFeedbackAction => ({
  type: types.WAITING_OTP_FEEDBACK
})

export const triggerWaitingOTPFeedbackComplete = (): WaitingOTPFeedbackCompleteAction => ({
  type: types.WAITING_OTP_FEEDBACK_COMPLETE
})
