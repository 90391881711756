import * as types from '@src/reducers/optionReducer'
import {
  FetchOptionsAction,
  FetchOptionsFailureAction,
  FetchOptionsSuccessAction
} from '@src/types/store/option'

export const fetchOptions = (): FetchOptionsAction => ({
  type: types.FETCH_OPTIONS
})

export const fetchOptionsSuccess = (
  response: Record<string, unknown>
): FetchOptionsSuccessAction => ({
  type: types.FETCH_OPTIONS_SUCCESS,
  payload: { response }
})

export const fetchOptionsFailure = (error: string): FetchOptionsFailureAction => ({
  type: types.FETCH_OPTIONS_FAILURE,
  payload: { error }
})
