import { PaymentBankActionTypes, PaymentBankState } from '@src/types/store/payment-bank'
import { merge } from 'lodash'

export const RESET_PAYMENT_FORM_SUBMIT = 'RESET_PAYMENT_FORM_SUBMIT'
export const UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS = 'UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS'
export const UPDATE_ORDER_PAYMENT_ACCOUNT_FAILURE = 'UPDATE_ORDER_PAYMENT_ACCOUNT_FAILURE'

export const INITIAL_STATE: PaymentBankState = {
  isFailure: false,
  isSubmitted: false
}

export default function paymentBankReducer(
  state: PaymentBankState = INITIAL_STATE,
  action: PaymentBankActionTypes
): PaymentBankState {
  switch (action.type) {
    case RESET_PAYMENT_FORM_SUBMIT:
      return { ...merge(state, { isFailure: false, isSubmitted: false }) }

    case UPDATE_ORDER_PAYMENT_ACCOUNT_SUCCESS:
      return { ...merge(state, { isFailure: false, isSubmitted: true }) }

    case UPDATE_ORDER_PAYMENT_ACCOUNT_FAILURE:
      return { ...merge(state, { isFailure: true, isSubmitted: true }) }

    default:
      return state
  }
}
