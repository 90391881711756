import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from '@src/config'

let bugsnagClient
const bugsnagEnable = config.NODE_ENV !== 'testing' && config.BUGSNAG_ID
if (bugsnagEnable) {
  bugsnagClient = Bugsnag.start({
    apiKey: config.BUGSNAG_ID,
    plugins: [new BugsnagPluginReact()],
    releaseStage: config.BUGSNAG_ENV,
    enabledReleaseStages: ['development', 'staging', 'production']
  })
} else {
  bugsnagClient = {
    notify() {
      return
    }
  }
}

export const submitEpicErrorToBugsnag = ({ mobile, requestBody, requestUrl, response }) => {
  if (bugsnagEnable) {
    if (!Bugsnag['client']) {
      Bugsnag.start({
        apiKey: config.BUGSNAG_ID,
        plugins: [new BugsnagPluginReact()],
        releaseStage: config.BUGSNAG_ENV,
        enabledReleaseStages: ['development', 'staging', 'production']
      })
    }
    Bugsnag.notify(new Error(), function (event) {
      if (response != null) {
        event.errors[0].errorMessage = JSON.stringify(response)
      }
      event.addMetadata('Detail', {
        Mobile: mobile,
        RequestBody: requestBody,
        RequestURL: requestUrl,
        ResponseBody: response
      })
    })
  }
}

export default bugsnagClient
