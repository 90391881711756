import loadingLottie from '@src/assets/lotties/loading.json'
import { i18n } from '@src/lib/i18n'
import { RootState } from '@src/reducers/rootReducer'
import { Text, colors } from '@welend/apple-shopping-cart-ui-library'
import Lottie from 'react-lottie-player'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Wrapper = styled.div<{
  open?: boolean
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
`

const Backdrop = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: ${colors.black};
  opacity: 0.4;
  height: 100vh;
  width: 100vw;
`

const Container = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 16px;
  position: absolute;
  width: 382px;
  height: 210px;
  background: #ffffff;
  border-radius: 6px;
`

const LoadingScreen = () => {
  const appData = useSelector((state: RootState) => state.app)

  return (
    <Wrapper open={appData.loading.isShow}>
      <Backdrop />
      <Container>
        <Modal>
          <Lottie
            loop
            play
            style={{ width: 96, height: 96, margin: '0 auto' }}
            animationData={loadingLottie}
          />
          <Text mt="4" align="center" textAlign="center">
            {appData.loading.message ? appData.loading.message : i18n.t('loading')}
          </Text>
        </Modal>
      </Container>
    </Wrapper>
  )
}

export default LoadingScreen
