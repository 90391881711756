import * as appActions from '@src/actions/appActions'
import * as authActions from '@src/actions/authActions'
import * as errorActions from '@src/actions/errorActions'
import * as optionActions from '@src/actions/optionActions'
import * as actions from '@src/actions/userActions'
import config from '@src/config'
import { API_ENDPOINT } from '@src/lib/constant'
import { i18n } from '@src/lib/i18n'
import * as types from '@src/reducers/userReducer'
import { OAuthValue } from '@src/types/store/auth'
import { push, replace } from 'connected-next-router'
import { camelizeKeys } from 'humps'
import querystring from 'querystring'
import { combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { AjaxError, AjaxResponse } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { request } from 'universal-rxjs-ajax'

const { WELAB_PAY_HOST } = config

function mapUrlHashToState(hash: string) {
  const queries = querystring.parse(hash.substring(1))
  const camelizedQuery = camelizeKeys(queries) as OAuthValue
  return {
    ...camelizedQuery,
    expiresAt: Date.now() + Number(camelizedQuery.expiresIn) * 1000
  }
}

export const changeLangEpic = (action$, state$) =>
  action$.pipe(
    ofType(types.CHANGE_LANG),
    switchMap(() => {
      const { lang } = state$.value.user
      i18n.changeLanguage(lang)
      document.body.setAttribute('lang', lang)
      return concat(of(actions.changeLangSuccess()), of(optionActions.fetchOptions()))
    }),
    catchError(() => {
      return concat(
        of(actions.changeLangFailure()),
        of(errorActions.changeError({ display: true }))
      )
    })
  )

export const updateLangEpic = (action$, state$) => {
  return action$.pipe(
    ofType(types.UPDATE_LANG),
    switchMap(({ payload }) => {
      const { lang } = payload
      return request({
        url: `${WELAB_PAY_HOST}${API_ENDPOINT.language}`,
        method: 'PUT',
        headers: {
          userToken: state$.value.auth.accessToken,
          applicationToken: state$.value.mall.mallState.resellerUuid,
          'Content-Type': 'application/json'
        },
        body: {
          language: lang
        }
      }).pipe(
        map((data: AjaxResponse) => {
          const response = camelizeKeys(data.response)
          const newLang: string = response['language']
          return newLang
        }),
        mergeMap((newLang: string) => {
          return concat(of(actions.updateLangSuccess(newLang)), of(actions.changeLang()))
        }),
        catchError((error: AjaxError) => {
          return of(
            errorActions.changeError({
              display: true,
              ajaxErrorResponseCode: error.status,
              requestBody: JSON.stringify(error?.request?.body),
              requestUrl: error?.request?.url,
              response: JSON.stringify(error?.response)
            })
          )
        })
      )
    })
  )
}

export const getUserEpic = (action$, state$) => {
  return action$.pipe(
    ofType(types.GET_USER),
    switchMap(() => {
      const urlHash = state$.value.router.location.hash
      const OAuthValue = mapUrlHashToState(urlHash)
      // update new state with Authenticated user and url hash
      if (OAuthValue?.accessToken && OAuthValue.expiresIn && OAuthValue.state) {
        return concat(
          of(
            authActions.loginStoreToken(OAuthValue),
            actions.updateUserState(OAuthValue?.state),
            appActions.changeUserReady()
          )
        )
      }

      // update new state with Authenticated user but no usr hash, use current auth state instead
      const authState = state$.value.auth.state
      return concat(of(actions.updateUserState(authState), appActions.changeUserReady()))
    })
  )
}

export default combineEpics(changeLangEpic, getUserEpic, updateLangEpic)
