import config from '@src/config'
import epics from '@src/epics/rootEpic'
import reducers, { EmptyState, RootState } from '@src/reducers/rootReducer'
import { createRouterMiddleware } from 'connected-next-router'
import { useMemo } from 'react'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const { ENABLE_REDUX_DEV_TOOLS } = config
const initStore = (initialState) => {
  const epicMiddleware = createEpicMiddleware()
  const logger = createLogger({
    collapsed: true,
    predicate: () => ENABLE_REDUX_DEV_TOOLS
  })
  const routerMiddleware = createRouterMiddleware()
  const reduxMiddleware = applyMiddleware(epicMiddleware, routerMiddleware, logger)
  const composeEnhancers = ENABLE_REDUX_DEV_TOOLS
    ? composeWithDevTools(reduxMiddleware)
    : reduxMiddleware
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
    blacklist: ['auth']
  }
  const persistedReducer = persistReducer(persistConfig, reducers)
  const store = createStore(persistedReducer, initialState, composeEnhancers)
  epicMiddleware.run(epics)

  return store
}

export const initializeStore = (preloadedState) => {
  let store

  let mstore = store ?? initStore(preloadedState)

  if (preloadedState && store) {
    mstore = initStore({
      ...store.getState(),
      ...preloadedState
    })
    store = undefined
  }

  if (typeof window === 'undefined') return mstore

  if (!store) store = mstore

  return mstore
}

export function useStore(initialState: RootState | EmptyState) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  const persistor = persistStore(store)
  return { store, persistor }
}
