import * as appActions from '@src/actions/appActions'
import * as appTypes from '@src/reducers/appReducer'
import { combineEpics, ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { delay, switchMap } from 'rxjs/operators'

export const appInitEpic = (action$, state$) => {
  return action$.pipe(
    ofType(appTypes.CHANGE_INIT),
    switchMap(() => {
      const { withToken } = state$.value.auth

      if (withToken) {
        return of(appActions.changeAppReady())
      }

      return of(appActions.changePageReady())
    })
  )
}

export const appReadyEpic = (action$) => {
  return action$.pipe(
    ofType(appTypes.CHANGE_PAGE_READY),
    switchMap(() => {
      return concat(of(appActions.changeAppReady()).pipe(delay(2000)))
    })
  )
}

export default combineEpics(appInitEpic, appReadyEpic)
