import ImageBlobReduce from 'image-blob-reduce'

const compressImage = async (file: File, size: number) => {
  const reduce = new ImageBlobReduce()
  const blob = await reduce.toBlob(file, { max: size })
  return new File([blob], file.name)
}

const getImageDimensions = (file: File): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = () => resolve({ height: img.height, width: img.width })
  })
}

export { compressImage, getImageDimensions }
