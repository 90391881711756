import { togglePopUp } from '@src/actions/appActions'
import { i18n } from '@src/lib/i18n'
import { Dialog, HR, Text, Title, mediaQueries } from '@welend/apple-shopping-cart-ui-library'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const Container = styled.div`
  padding: 64px 24px 222px;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media ${mediaQueries.desktop} {
    padding: 56px 48px 0;
  }
`

const StyledTitle = styled(Title)`
  line-height: 49px;
`

const ContactUs = (): JSX.Element => {
  const contactUsTheme = {
    minHeight: '289px',
    size: {
      mm: {
        width: '354px'
      },
      tablet: {
        width: '580px'
      }
    }
  }
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(
      togglePopUp({
        isShow: false,
        type: ''
      })
    )
  }
  return (
    <Dialog theme={contactUsTheme} onClose={onClose} closable>
      <Container>
        <StyledTitle fontWeight="bold" typeface="large">
          {i18n.t('common:need_assistance')}
        </StyledTitle>
        <HR mb={4} mt={4} />
        <Text typeface="large">{i18n.t('common:contact_number')}</Text>
        <Text typeface="large">{i18n.t('common:working_hour')}</Text>
        <Text typeface="large">{i18n.t('common:contact_email')}</Text>
      </Container>
    </Dialog>
  )
}

export default ContactUs
