import { ProgressComponent } from '@src/components'
import { Auth } from '@src/components/Auth'
import { ErrorNotifier } from '@src/components/ErrorNotifier'
import Body from '@src/components/Layout/Body'
import FooterComponent from '@src/components/Layout/FooterComponent'
import HeaderComponent from '@src/components/Layout/HeaderComponent'
import { LoadingScreen } from '@src/components/LoadingScreen'
import PopUp from '@src/components/Popup'
import { Theme } from '@src/components/Theme'
import config from '@src/config'
import bugsnagClient from '@src/lib/bugsnag'
import { getWarningRouter } from '@src/lib/helper'
import { appWithTranslation, i18n } from '@src/lib/i18n'
import Warning from '@src/pages/warning'
import { useStore } from '@src/store/store'
import { GlobalStyles } from '@welend/apple-shopping-cart-ui-library'
import { ConnectedRouter } from 'connected-next-router'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

const MyApp = ({ Component, pageProps }) => {
  const { store, persistor } = useStore(pageProps.initialReduxState)
  const router = useRouter()

  const isBrowser = typeof window !== 'undefined'
  const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React)
  const isWarning = getWarningRouter(router.pathname)

  useEffect(() => {
    const { NODE_ENV } = config
    if (isBrowser && NODE_ENV !== 'development') {
      TagManager.initialize({ gtmId: config.ANALYTICS_GA })
    }
  }, [isBrowser])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <Head>
            <title>{i18n.t('company')}</title>
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          </Head>
          <ConnectedRouter>
            <Theme>
              <GlobalStyles />
              <ErrorNotifier />
              <PopUp />
              <Auth>
                <LoadingScreen />
                <HeaderComponent />
                <ProgressComponent />
                <Body>{isWarning ? <Warning /> : <Component {...pageProps} />}</Body>
                <FooterComponent />
              </Auth>
            </Theme>
          </ConnectedRouter>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  )
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  return { pageProps }
}

export default appWithTranslation(MyApp)
